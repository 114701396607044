import React from "react"



class WeProvide extends React.Component {

  render() {
    return (
      <div className="pt-100">
      <section className="usr-admin-tab benifor consult mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Role </span>of an ICO consultant</h2>
            <p className="text-center">
            Our ICO consultant is your trusted guide throughout the entire process of launching an Initial Coin Offering (ICO). We provide proper guidance on token design, token type, supply, distribution, etc... Let us help you navigate the complexities of your ICO and achieve your goals with confidence.
            </p>
            <div className="row">
                  <div className="d-lg-flex flex-wrap add-one px-0">
                    <div className="square" >
                      <h3>Market Research & Analysis</h3>
                      <p className="pharagraph">
                      As leading ICO consultants, we conduct thorough market research to identify trends, target audiences, and competitors. We analyze data to inform the project's strategy and positioning. With our consultation, you can get a clear-cut idea about the purpose of your ICO project.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Token Design & Development</h3>
                      <p className="pharagraph">
                      We assist you in creating and structuring the token, including its utility and economic model. Further, we guide on technical aspects like smart contract development and token distribution mechanisms, etc...
                      </p>
                    </div>
                    <div className="square" >
                      <h3>White Paper Creation</h3>
                      <p className="pharagraph">
                      Coinsclone's reputed ICO consulting services help you in drafting the White Paper, detailing the project's vision, technology, roadmap, tokenomics, and many more. This helps in gaining investor confidence and interest in your ICO project.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Legal Regulatory Compliance</h3>
                      <p className="pharagraph">
                      We elaborate and ensure that your ICO complies with relevant laws and regulations. Coordinate with our legal experts to understand securities laws, anti-money laundering policies, and other regulatory requirements.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Community Management</h3>
                      <p className="pharagraph">
                      We help you out in managing communication with your investors, providing updates, and handling inquiries to maintain transparency and trust. We support you with the effective engagement of the community to foster trust.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Technical Support</h3>
                      <p className="pharagraph">
                      As a top ICO consulting service provider, we offer technical support throughout the ICO process. This includes elaborating on blockchain integration and choosing security audits, etc. Robust technical support is essential for a successful ICO launch.
                      </p>
                    </div>
                  </div>
            </div>
          </div>
      </section>
      </div>
    )
  }
}

export default WeProvide
