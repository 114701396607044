import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center"><span className='bluecolor'>ICO Consulting </span>Services - Coinsclone </h2>
            <div className="text-center">
              <p className="pharagraph text-center">ICO Consulting Services offers guidance and support to launch Initial Coin Offerings (ICOs) to raise funds for their projects.  In ICO, the funds are raised by selling new tokens to potential investors. Coinsclone offers expert and uniquely crafted services to help clients navigate complex ICO projects, maximizing the potential for successful fundraising and project implementation. At Coinsclone, we provide top-notch ICO consulting services and our experts offer detailed information, personalized advice, and extensive guidance for your ICO project.
              </p>
              <p className="pharagraph text-center mb-0">Ignite Your Launch, Master Your Success…
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis