import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Why Choose Coinsclone </span>for Your ICO Consulting Services?</h2>
              
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/ico-consult/why-choose-ico-consult.webp"
                alt="best crypto app development company"
                placeholder='none'
                className='mt-3 mb-3'
                width={394}
              />
            </div>
            <div className="col-lg-6 col-md-12">
            <p className="pharagraph">Coinsclone stands out as a reputed ICO consulting services provider in today's market. Our team guides you through every step, ensuring you receive the best advice. Our success stories reflect our commitment and best services that aligns with unmatched success in ICO projects. We believe that when customer value is met, success follows naturally. Our developer team works diligently to bring our client's visions to life. On the whole, we help you to create a unique identity for your businesses in the market.</p>
              <ul className='mt-3 mb-0'>
                <li>150+ successful ICO projects</li>
                <li>Expert team of Developers</li>
                <li>Affordable Cost for Startups</li>
                <li>24*7 Technical Support</li>
                <li className='mb-0'>High - level Quality and Best Service</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose