import React from "react"

class BenifitsOf extends React.Component {

  render() {
    return (
      <section className="dapps consult pt-100 mb-0">
        <div className="gray-bg">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Our Premium </span>ICO Consulting Services</h2>
            <p className='text-center'>Coinsclone propels your ICO project to success with expert consulting services. Being a reputed company we guide you from initial planning to launch and we have got you covered!</p>
          <div className="row">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>ICO Landing Page Consultation</h3>
                            <p className='pharagraph'>We assist by offering clear-cut ideas about designing and optimizing your ICO landing page to grab the attention of potential investors. Get an entire consultation on crafting compelling content, ensuring user-friendly navigation, incorporating essential features, etc.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Legalization and KYC</h3>
                            <p className='pharagraph'>Our expert team deals with the important aspects of your ICO, by addressing the legal complexities of launching an ICO. Get elaborate ideas on the legalization and KYC service that adheres to international laws and regulations. Thus, we guide you in implementing a robust KYC process.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Customized Token Creation</h3>
                            <p className='pharagraph'>Our expert team guides you with the customized token creation needed for your ICO project. We will define you the token's structure, functionality, and integration with blockchain technology. Ensure your tokens meet specific requirements and support your overall ICO strategy with us.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Whitepaper Drafting</h3>
                            <p className='pharagraph'>ICO Whitepaper drafting is crucial for communicating your project's vision, technology, and benefits to potential investors. Our experts draft a professional whitepaper. We cover all essential aspects, including technical details, tokenomics, roadmap, etc. to present a detailed overview.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>ICO Listing Consultations</h3>
                            <p className='pharagraph'>Getting your ICO tokens listed on reputable platforms and exchanges is the most important act for visibility and credibility. Our ICO Listing Consultations service guides you through selecting the right platforms, preparing the necessary documentation, and managing the listing process.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>ICO Launch Consultation</h3> 
                            <p className='pharagraph'>Launching an ICO successfully requires careful planning and execution. Our ICO Launch Consultation service provides complete support, including developing a launch strategy, creating marketing plans, building a community, managing the launch timeline, and many more. </p>
                        </div>
                    </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default BenifitsOf
