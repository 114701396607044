import React from 'react'


class UserFriendly extends React.Component {

  render() {
    return (
      <section className="queries">
        <div className="container">
          <div className="text-center">
            <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn mb-4">Book a calendly meeting</a>
          </div>
        </div>
      </section>
    )
  }
}

export default UserFriendly