import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

class TechStack extends React.Component {


  render() {
    return (
      <div className='pt-100'>
        <div className='gray-bg'>
          <section className="binancestack p-0">
            <div className="container">
              <div className="row table-content">
                <div className="col-md-12 col-lg-6 pr-lg-5">
                  <h3 className="heading-h2"><span className='bluecolor'>Technologies Used</span> for our Our ICO Consulting Services</h3>
                  <p className="pharagraph">Fund your future with a long-term technology stack, third-party tools, APIs, blockchain networks, and resources. We imply a highly advanced-level tech stack for cryptocurrency development. 
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.png"
                    alt="Technologies stock"
                    className='logostck'
                    width={573}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default TechStack             