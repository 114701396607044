import React from "react"

class HowtoGet extends React.Component {

  render() {
    return (

      <section className="icon whitelabel-use consult pt-100 secureof">
        <div className="container">
          <h2 className="heading-h2 text-center">Our ICO Consulting Services <span className='bluecolor'>Workflow</span></h2>
          <p className='text-center mw1030'>Launch a successful ICO project by acquiring a well-planned workflow from our premium consulting services.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                Requirement Gathering
                </h3>
                <p className="pharagraph">We start by understanding your project's goals, target audience, and unique value proposition. In this phase detailed discussions and documentation to ensure all your requirements take place. We assess you with the feasibility and scope of your ICO project. Also, we work with you every step of the way to ensure a smooth and successful ICO launch.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Strategy Development
                </h3>
                <p className="pharagraph">Our experts at Coinsclone will analyze the gathered requirements to develop a prompt strategy. It includes market research, competitor analysis, identifying key differentiators, etc. After this, a step-by-step roadmap for your ICO is prepared. By doing this your project's potential and market impact are maximized.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Community Building
                </h3>
                <p className="pharagraph mb-lg-0">We design and implement a targeted marketing strategy to promote your ICO. Doing social media campaigns, content creation, and community engagement, we cover every possible way of marketing. Our team helps you build a sturdy online presence and nurture a loyal community. Our effective marketing is key to reaching your potential investors and driving participation in your ICO.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Legal Regulatory Compliance
                </h3>
                <p className="pharagraph mb-lg-0">Navigating the legal context of ICOs is crucial for success. We provide expert guidance on regulatory requirements in different jurisdictions as well. These services include legal documentation, compliance checks, risk assessment, etc. We ensure that your ICO adheres to legal standards and enhances investor confidence.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default HowtoGet
