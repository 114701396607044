import React from "react"

class DevProcess extends React.Component {

  render() {
    return (

      <section className="fine-box consult pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Benefits of </span>Partnering with Coinsclone</h2>
            <p className="text-center mb-2">
            Coinsclone offers you a complete package of benefits by partnering with us. Let's discover the extensive benefits that come with our consultation. Your ICO's best partner for success and growth is here!
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Holistic ICO Services</h3>
                <p className="pharagraph">
                Coinsclone offers a complete spectrum of ICO-related services, and every aspect of your ICO is expertly managed. Starting from initial planning and token creation to marketing, legal compliance, and post-launch support, we provide end-to-end solutions. You can focus on your core business while we handle the complexities of your ICO.
                </p>
              </div>
              <div className="square" >
                <h3>Enhanced Investment Appeal</h3>
                <p className="pharagraph">
                Our premium ICO consultation service will enhance the attractiveness of your ICO to potential investors. We guide you with crafting compelling whitepapers, designing engaging landing pages, implementing effective marketing strategies, etc. Thus we help you to attract a larger pool of investors, which increases the success of your ICO project.
                </p>
              </div>
              <div className="square" >
                <h3>ICO Based Growth Opportunities</h3>
                <p className="pharagraph">
                Coinsclone opens up ample opportunities for both ICO and business development. Our strategic guidance and industry insights help you to understand the evolving blockchain technology and crypto markets. We assist in identifying new growth opportunities, optimizing your business model, expanding your market reach, etc.
                </p>
              </div>
              <div className="square" >
                <h3>Blockchain Technology Expertise</h3>
                <p className="pharagraph">
                With deep expertise in blockchain technology, Coinsclone provides cutting-edge solutions for your ICO project. We have a team of blockchain engineers and consultants who stay up-to-date with the latest advancements and trends. With our consulting service, you can get prompt insights regarding every nook and corner of your ICO project.
                </p>
              </div>
              <div className="square" >
                <h3>Personalized Consulting Services</h3>
                <p className="pharagraph">
                We very well understand that each project is unique, which is why we offer personalized consulting services. We take the time to understand your specific needs, goals, and challenges and provide suitable solutions that align with your vision. Our dedicated consultants work closely with you throughout the entire ICO project.
                </p>
              </div>
              <div className="square" >
                <h3>Community and Network Building</h3>
                <p className="pharagraph">
                Building a strong community and network is hard for the success of your ICO. We help you with ideas for developing and engaging a vibrant community of supporters, investors, and partners. From social media strategies and community management, we support your project in all possible ways.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  } 
}

export default DevProcess
