import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="heading-h2 text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is the role of an ICO consulting service provider?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The role of an leading ICO consulting service provider is to provide guidance and support to startups on various aspects of the ICO process. This includes developing a solid business plan, analyzing the target market, and compelling the value proposition for investors.   
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                How can ICO consulting services enhance marketing strategies?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">ICO consulting services enhance marketing strategies by offering industry expertise advice to craft targeted campaigns, optimize online presence through SEO and content marketing, and utilize data-driven approaches for investor outreach. As reputed 
                <a href="https://www.coinsclone.com/ico-development-company/"> <b>ICO development company</b></a>, with our deep understanding of the cryptocurrency market, we ensure that marketing efforts are effective, compliant with regulations, and tailored to attract and gain potential investors.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How can ICO consulting services help navigate regulatory challenges?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Our ICO consulting services help to navigate regulatory challenges by providing expert guidance on compliance with relevant laws and regulations. We ensure that all marketing and operational activities adhere to legal standards. We assist you in drafting and reviewing legal documents, facilitate communication with regulatory bodies, etc...So that you can stay updated on regulatory changes to mitigate risks and ensure smooth operations.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                What ongoing support is provided after the ICO launch?
                </Accordion.Toggle>
              </div>  

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">After the successful ICO launch, ongoing support is given. It includes maintaining investor relations, managing community engagement, and delivering continuous marketing and PR efforts to sustain interest and growth. Additionally, technical support, updates on regulatory compliance, challenges, and future opportunities are all provided.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection